<template>
    <div class="Meeting" id="Meeting">
        <div style="height: 65px;line-height: 65px" class="warp-padding flex">
            <router-link to="/meeting">
                <div>
                    <a-icon class="return" type="left"/>&nbsp;&nbsp;学习计划详情
                </div>
            </router-link>
            <div>
                <router-link to="/meeting">
                    <a-button type="primary" class="release">返回</a-button>
                </router-link>
            </div>
        </div>
        <a-row :gutter="19">
            <!--  左边-->
            <a-col :span="16">
                <div style="background: #ffffff" class="" class="warp-padding flex">
                    <div class="coverImg">
                        <img :src="info.Photo + '?imageView2/1/w/520/h/292'" alt=""/>
                    </div>

                    <div class="coverRight">
                        <div class="flex">
                            <div class="title">
                                {{ info.Title }}
                            </div>
                            <span
                                    class="list-status"
                                    :class="info.State == '进行中' ? 'list-status-red' : ''"
                            >{{ info.State }}</span
                            >
                        </div>

                        <div class="line-margin">
                            <span class="person-text">负责人 </span>
                            <!-- {{info.PersonInCharge.indexOf(',')}} -->
                            <div
                                    class="PersonInCharge"
                                    v-if="info.PersonInCharge.length > 0"
                                    v-for="item in info.PersonInCharge"
                            >
                                <a-avatar
                                        v-if="item.icon"
                                        class="person-avatar"
                                        :size="42"
                                        icon="user"
                                        shape="circle"
                                        :src="item.icon"
                                />
                                <span>{{ item.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <a-row :gutter="19" style="margin-bottom: 20px">
                    <a-col :span="10">
                        <div class="base-warp">
                            <h1 class="base-text">基本信息</h1>
                            <div class="flex group" style="justify-content: start;">
                                <span class="label-tips">创建人</span>
                                <span>{{ info.CreateUser }}</span>
                            </div>
                            <div class="flex group" style="align-items:baseline;width: 100%;">
                                <span class="group-label">时间</span>
                                <div class="flex-1">
                  <span>{{ info.StartTime | sliceFn(0, 16) }}</span
                  ><br/>
                                    <span>{{ info.EndTime | sliceFn(0, 16) }}</span>
                                </div>
                            </div>

                            <div class="flex group">
                                <span class="group-label">地点</span>
                                <span class="flex-1">{{ info.MeetingAddress }}</span>
                            </div>
                            <div class="flex group">
                                <span class="group-label">形式</span>
                                <span class="flex-1" v-show="info.Form == 1">线上</span>
                                <span class="flex-1" v-show="info.Form == 2">线下</span>
                                <span class="flex-1" v-show="info.Form == 3">线上线下</span>
                            </div>
                            <div class="flex group">
                                <span class="group-label">人数</span>
                                <span class="flex-1">{{
                  JoinPersoin.length > 0 ? JoinPersoin.length : '0'
                }}</span>
                            </div>
                            <div class="flex group" style="align-items:baseline;width:100%;">
                                <span class="group-label">参与方式</span>
                                <div
                                        v-if="info.JoinType == 2"
                                        class="flex-1"
                                        style="white-space: unset;"
                                >
                                    点击我要报名，快来抢有限开放的{{ info.SignUp }}个～
                                </div>
                                <span v-else class="flex-1">邀请</span>
                            </div>
                            <div class="flex group">
                                <span class="group-label">学费</span>
                                <span class="flex-1">{{ info.CostFree }}</span>
                            </div>
                            <div class="flex group">
                                <span class="group-label">课程类型</span>
                                <span class="flex-1">{{categoryObject[ info.Category] }}</span>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="14">
                        <div class="base-warp" style="padding: 17px 20px">
                            <div class="flex drawer-content-bottom">
                                <h1 class="base-text">
                                    参与人员
                                    <span style="color:rgba(153,153,153,1);font-size: 14px"
                                    >共{{
                      JoinPersoin.length > 0 ? JoinPersoin.length : '0'
                    }}人</span
                                    >
                                </h1>
                                <div>
                                    <a-input
                                            v-model="copyUrl"
                                            ref="copy"
                                            style="position: absolute;opacity: 0;z-index:-1"
                                    />
                                    <a-button style="margin-right: 10px;" @click="_copyUrl"
                                    >复制链接
                                    </a-button
                                    >
                                    <!--v-if="JoinType==2"-->
                                    <a-button type="primary" @click="signup">我要报名</a-button>
                                </div>
                            </div>

                            <div
                                    class="group"
                                    style="display: inline-block;position: relative"
                                    v-if="JoinPersoin && loadShow"
                                    v-for="item in JoinPersoin"
                            >
                                <a-avatar
                                        :size="42"
                                        icon="user"
                                        shape="circle"
                                        :src="item.icon"
                                />
                                <div class="join-name">{{ item.title }}</div>
                            </div>
                            <span
                                    style="margin-right:15px;margin-bottom:15px;white-space: nowrap;"
                                    v-if="!loadShow"
                                    v-for="(item, index) in JoinPersoin"
                                    class="join-name"
                            >{{ item.ParticipantName }}</span
                            >
                            <a-icon
                                    type="ellipsis"
                                    v-show="loadShow"
                                    style="fontSize:24px;fontWidth:bold"
                                    title="加载更多"
                                    @click="loadMoveFn()"
                            />
                        </div>
                    </a-col>
                </a-row>
                <!--                    导师介绍start-->
                <div style="" class="warp-padding">
                    <h1 class="base-text">课程概况</h1>
                    <div class="flex">
                        <h2 class="tutor-text">导师介绍</h2>
                    </div>

                    <div
                            class="tutor"
                            v-if="info.tutorList.length > 0"
                            v-for="item in info.tutorList"
                    >
                        <div class="flex">
                            <div style="width: 92px">
                                <a-avatar
                                        class="person-avatar"
                                        :size="92"
                                        icon="user"
                                        shape="circle"
                                        :src="item.icon"
                                />
                            </div>
                            <div class="tutor-info">
                                <div class="tutor-name">
                                    <span style="margin-right:5px">{{ item.title }}</span>
                                </div>

                                <div class="tutor-base" :title="item.InfoAll">
                                    {{ item.Info }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 class="tutor-text">课程介绍</h2>
                    <span
                            style="word-wrap:break-word"
                            v-if="info.Remark"
                            v-html="info.Remark"
                    ></span>
                </div>
                <!--                    导师介绍end-->
                <!--热门评论start-->
                <div class="warp-padding">
                    <div class="flex">
                        <h1 class="base-text">热门评论</h1>

                        <a-popconfirm
                                placement="bottom"
                                cancelText="取消"
                                okText="确定"
                                @confirm="expel_comment"
                                @cancel="maxClassify = -1"
                        >
                            <template slot="title">
                                <p>确定要导出么？</p>
                            </template>
                            <a-button type="primary">导出评论</a-button>
                        </a-popconfirm>
                    </div>

                    <a-list v-if="data" v-for="(item, index) in data">
                        <a-list-item>
                            <a-list-item-meta :description="item.Content">
                                <a slot="title"
                                >{{ item.CreateUserName }}&nbsp;&nbsp;{{ item.CreateTime }}</a
                                >
                                <a-avatar slot="avatar" :src="item.Avatar"/>
                            </a-list-item-meta>
                        </a-list-item>
                    </a-list>
                    <div class="comment">
                        <a-textarea
                                id="comment"
                                v-model="Content"
                                style="margin: 24px 0"
                        ></a-textarea>
                        <div class="comment-bth" @click="comment(1)">发表</div>
                    </div>
                </div>
                <!--热门评论end-->
                <!--我的笔记start-->
                <div class="warp-padding" v-show="noteStatus">
                    <h1 class="base-text">我的笔记</h1>
                    <a-list v-if="note.length > 0" v-for="item in note">
                        <a-list-item>
                            <a-list-item-meta :description="item.Content">
                                <a slot="title"
                                >{{ item.CreateUserName }}&nbsp;&nbsp;{{ item.CreateTime }}</a
                                >
                                <a-avatar slot="avatar" :src="item.Avatar"/>
                            </a-list-item-meta>
                        </a-list-item>
                    </a-list>
                    <div class="comment" v-if="!commentId">
                        <a-textarea
                                id="notes"
                                v-model="NoteContent"
                                style="margin: 24px 0"
                        ></a-textarea>
                        <div class="comment-bth" @click="comment(2)">发表</div>
                    </div>
                    <div class="comment" v-else>
                        <a-textarea
                                id="notes"
                                v-model="NoteContent"
                                style="margin: 24px 0"
                        ></a-textarea>
                        <div class="comment-bth" @click="Savecomment(2)">编辑</div>
                    </div>
                </div>
                <!--我的笔记end-->
            </a-col>

            <!--                右边-->
            <a-col :span="8">
                <div class="warp-padding" style="height: 364px;overflow-y: auto">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">活动风采</h1>
                        <a-button v-if="Id" type="primary" style="position: relative">
                            上传图片
                            <input
                                    type="file"
                                    class="upload-bth"
                                    id="ActivityImg"
                                    @change="ActivityImg($event, 'ActivityImg', 1)"
                            />
                        </a-button>
                    </div>
                    <div v-viewer v-if="info.files">
                        <div
                                v-if="info.files.photo"
                                v-for="(item, index) in info.files.photo"
                                class="imsges"
                        >
                            <img :src="item.FileUrl" alt=""/>
                            <div class="img-del">
                                <a-popconfirm
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="ImgDel(index, item.Id)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>确定删除么？</p>
                                    </template>
                                    <i
                                            style="color:#ffffff;cursor: pointer"
                                            class="iconfont icon-shanchu- ant-menu-item-selected"
                                    ></i>
                                </a-popconfirm>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="warp-padding" style="height: 260px;overflow-y: auto">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">相关视频</h1>
                    </div>
                    <div v-show="Video" v-for="item in Video" class="video">
                        <video controls="controls" controlslist="nodownload" :src="item.FileUrl"></video>
                        <div class="video-title">{{ item.FileName }}</div>
                        <div class="flex">
                            <span class="video-upload-time">上传于{{ item.nowTime }}</span>
                        </div>
                        <div class="shade" @click="_selectVideo(item)"></div>
                        <a-icon
                                type="play-circle"
                                @click="_selectVideo(item)"
                                class="play-btn"
                        />
                    </div>
                </div>

                <div class="warp-padding" v-if="info.CourseFile">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">所用课件</h1>
                    </div>
                    <div
                            class="flex"
                            style="justify-content: left;margin-bottom: 15px;flex-wrap: wrap"
                    >
                        <div
                                class="upload-file-item"
                                v-for="item in info.CourseFile"
                                @click="priewCourse(item.FileUrl, item.FileType)"
                        >
                            <div>
                                <a-icon type="file-pdf"/>
                                <span
                                        style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block"
                                >{{ item.FileName }}</span
                                >
                            </div>
                            <!--<a-dropdown :trigger="['contextmenu']">-->
                            <!--<div>-->
                            <!--<a-icon type="file-pdf" />-->
                            <!--<span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block">{{item.FileName}}</span>-->
                            <!--</div>-->
                            <!--<a-menu slot="overlay">-->
                            <!--<a-menu-item key="2" @click="downCourse(item.FileUrl,item.FileName)">-->
                            <!--<i class="iconfont icon-xiazai-">&nbsp;下载</i>-->
                            <!--</a-menu-item>-->
                            <!--<a-menu-item key="3" @click="priewCourse(item.FileUrl,item.FileType)">-->
                            <!--<i class="iconfont icon-yulan">&nbsp;预览</i>-->
                            <!--</a-menu-item>-->
                            <!--</a-menu>-->
                            <!--</a-dropdown>-->
                            <!--<a-icon type="file-pdf" />-->
                            <!--<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.FileName}}</div>-->
                        </div>
                    </div>
                </div>
                <div class="warp-padding" v-if="info.QuestionnaireId">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">试卷</h1>
                    </div>
                    <div
                            v-for="item in info.QuestionnaireId"
                            class="quire-content"
                            @click="routeFn(item.Id, 2)"
                    >
                        <span class="quire-icon"></span>
                        <span class="quire-title">{{ item.Title }}</span>
                    </div>
                </div>
                <div class="warp-padding" v-if="info.PaperId">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">问卷</h1>
                    </div>
                    <div
                            v-for="item in info.PaperId"
                            class="quire-content"
                            @click="routeFn(item.Id, 1)"
                    >
                        <span class="Paper-bg"></span>
                        <span class="quire-title">{{ item.Title }}</span>
                    </div>
                </div>

                <div class="foot">
                    <div class="foot-button" @click="foot">
                        <a-button shape="circle" size="large">
                            <i class="iconfont icon-xiangshang"></i>
                        </a-button>
                    </div>
                    <div class="foot-button">
                        <a-button
                                shape="circle"
                                size="large"
                                @mouseover="commentshow"
                                @mouseleave="commentout"
                                @click="commentlist(1, 1)"
                        >
                            <i v-if="!commentStatus" class="iconfont icon-comment"></i>
                            <div v-else class="foot-text">写评论</div>
                        </a-button>
                    </div>
                    <div class="foot-button">
                        <a-button
                                shape="circle"
                                size="large"
                                @mouseover="noteshow"
                                @mouseleave="notetout"
                                @click="commentlist(2, 2)"
                        >
                            <i v-if="!showStatus" class="iconfont icon-iconset0137"></i>
                            <div v-else class="foot-text">记笔记</div>
                        </a-button>
                    </div>
                </div>
            </a-col>
        </a-row>
        <!--课件预览start-->

        <div class="popup-warp" v-if="FileType">
            <div class="popup-shadw"></div>
            <div class="popup-content">

                <!--<iframe-->
                <!--v-if="FileStatus == 2"-->
                <!--:src="'https://view.officeapps.live.com/op/view.aspx?src=' + FileUrl"-->
                <!--width="100%"-->
                <!--height="100%"-->
                <!--frameborder="0"-->
                <!--&gt;</iframe>-->
                <div
                        style="position: fixed;left: 50%;top: 50%;transform: translate(-50%,-50%);"
                        v-if="FileStatus == 1"
                >
                    <img :src="FileUrl" alt=""/>
                </div>
                <div
                        v-if="FileStatus == 3"
                        style="position: fixed;left: 50%;top: 50%;transform: translate(-50%,-50%);width: 100%;height: 100%"
                >
                    <object style="width: 100%;height: 100%" border="0" :data="FileUrl">
                    </object>
                </div>
            </div>
            <div @click="FileClose" class="popup-close">
                <i style="font-size: 18px" class="iconfont icon-guanbi"></i>
            </div>
        </div>
        <!--课件预览end-->
        <div class="alert-video" v-show="alertVideo">
            <video
                    :src="alertVideoUrl"
                    class="play-video"
                    controls
                    @play="_strtVideo"
                    @pause="_endVideo"
                    @ended="_endedVideo"
                    controlslist="nodownload"
            ></video>
        </div>
        <div
                class="alert-video-shade"
                v-show="alertVideo"
                @click="_closeAlertVideo"
        ></div>
        <iframe
                v-if="FileType && FileStatus == 2"
                :src="'https://view.officeapps.live.com/op/view.aspx?src=' + FileUrl"
                width="100%"
                height="100%"
                frameborder="0"
                style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index: 1000"
        ></iframe>
        <div class="look_wrap" v-if="FileType && FileStatus == 2">
            <div @click="FileClose" class="popup-close">
                <i style="font-size: 18px;color: #f00" class="iconfont icon-guanbi"></i>
            </div>
        </div>
    </div>
</template>
<script>
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'

    Vue.use(Viewer)
    export default {
        name: 'meeting',
        data() {
            return {
                alertVideo: false,
                alertVideoUrl: '',
                alertVideoName: '',
                duration: 0,
                timer: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                Id: this.$route.query.id ? this.$route.query.id : '',
                info: '',
                Video: '',
                Content: '',
                NoteContent: '',
                Type: '',
                data: [],
                note: [],
                noteStatus: false,
                commentStatus: false,
                showStatus: false,
                commentId: this.$route.query.commentId ? this.$route.query.commentId : '',
                FileType: false,
                FileUrl: '',
                FileStatus: '',

                JoinPersoin: [],
                loadShow: true,

                copyUrl: '',//复制链接
                categoryObject:{},
                
            }
        },
        created() {
            this._categoryList();
        },
        mounted() {
            if (this.Id) {
                this.copyUrl = 'https://mskim.sikegroup.com/studyPlanDetail?id=' + this.Id
                this.loadData()
                this.commentlist(1, 0)
                if (this.commentId) {
                    let self = this
                    this.$axios.post(
                        1043,
                        {
                            Id: self.commentId,
                            uid: self.userinfo.uid
                        },
                        res => {
                            self.noteStatus = true
                            self.NoteContent = res.data.message.Content
                        }
                    )
                }
            }
            window.addEventListener('scroll', this.windowScroll())
        },
        methods: {
            _categoryList() {
                let self = this
                this.$axios.get(
                    1200,
                    {
                        label: 'Study'
                    },
                    res => {

                        for(let i=0;i<res.data.data.length;i++){
                            self.categoryObject[res.data.data[i].Id] =  res.data.data[i].title;
                        }
                       
                    }
                )
            },
            _endedVideo() {
                let data = {
                    PlanId: this.info.Id, //学习计划id
                    PlanName: this.info.Title, //学习计划名称
                    VideoUrl: this.alertVideoUrl, //视频地址
                    CreateUserId: this.userinfo.uid, //观看人id
                    CreateUser: this.userinfo.name, //观看人名称
                    ViewDuration: this.duration, //观看时长（单位/秒）
                    VideoName: this.alertVideoName //视频名称
                }
                this.$axios.post(5836144, data, function (res) {
                    if (res.data.code == 1) {
                        return 'OK'
                    }
                })
                this.timer = ''
                this.duration = 0
            },
            _strtVideo() {
                let self = this
                this.timer = setInterval(function () {
                    self.duration += 1
                }, 1000)
            },
            _endVideo() {
                clearInterval(this.timer)
                console.log(this.duration)
            },
            _closeAlertVideo() {
                this.alertVideo = false
                document.getElementsByClassName('play-video')[0].pause()
                if (this.duration == 0) {
                    return false
                }
                let data = {
                    PlanId: this.info.Id, //学习计划id
                    PlanName: this.info.Title, //学习计划名称
                    VideoUrl: this.alertVideoUrl, //视频地址
                    CreateUserId: this.userinfo.uid, //观看人id
                    CreateUser: this.userinfo.name, //观看人名称
                    ViewDuration: this.duration, //观看时长（单位/秒）
                    VideoName: this.alertVideoName //视频名称
                }
                this.$axios.post(5836144, data, function (res) {
                    if (res.data.code == 1) {
                        return 'OK'
                    }
                })
            },
            _selectVideo(data) {
                this.alertVideo = true
                if (this.alertVideoUrl != data.FileUrl) {
                    this.alertVideoUrl = data.FileUrl
                    this.alertVideoName = data.FileName
                    this.timer = ''
                    this.duration = 0
                }
                console.log(data)
            },
            _copyUrl() {
                this.$refs.copy.select()
                document.execCommand('Copy')
                this.$message.success('复制成功')
            },
            loadMoveFn() {
                let that = this
                that.$axios.get(8021, {id: that.Id}, res => {
                    if (res.data.code == 1) {
                        this.loadShow = false
                        that.JoinPersoin = []
                        that.JoinPersoin = res.data.data
                        console.log(that.JoinPersoin)
                        // if(that.JoinPersoin.length>20){this.loadShow = false;}
                    } else {
                        that.$message.error(res.data.msg)
                    }
                })
            },
            //跳转问卷试卷二维码的方法
            routeFn(id, type) {
                this.$router.push({
                    path: '/issueQuestionnaire',
                    query: {id: id, type: type}
                })
            },
            windowScroll() {
                // 滚动条距离页面顶部的距离
                // 以下写法原生兼容
                let scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop
                console.log(scrollTop)
            },
            Savecomment() {
                let self = this
                this.$axios.post(
                    1044,
                    {
                        Id: self.commentId,
                        uid: self.userinfo.uid,
                        Content: self.NoteContent
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.commentId = false
                            self.commentlist(2, 2)
                            self.NoteContent = ''
                            this.$message.success(red.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            loadData() {
                let self = this
                this.$axios.get(
                    1026,
                    {
                        Id: self.Id
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.info = res.data.message
                            self.JoinPersoin = res.data.message.JoinPersoin
                            // console.log(self.info);
                            if (res.data.message.Video) {
                                self.Video = JSON.parse(res.data.message.Video)
                            } else {
                                self.Video = []
                            }
                        } else {
                            this.$message.error('查询数据失败!')
                        }
                    }
                )
            },
            ImgDel(i, id) {
                let self = this
                this.$axios.post(
                    1037,
                    {
                        uid: self.userinfo.uid,
                        Id: id,
                        plan_id: self.Id
                    },
                    res => {
                        console.log(res.data.code)
                        if (res.data.code == 1) {
                            self.info.files.photo.splice(i, 1)
                            self.$message.success(res.data.message)
                        } else {
                            self.$message.error(res.data.message)
                        }
                    }
                )
            },
            expel_comment() {
                let self = this
                window.open(
                    'http://ims.qitianzhen.cn/index.php?m=Kms&c=Batch&a=meeting_excel&MeetingId=' +
                    self.Id
                )
                // let file = ''; // 文件流
                //
                // this.$axios.post(1019,{
                //     plan_id:self.Id,
                //     Type:1
                // },res=>{
                //    console.log(res.data);
                //     let blob = new Blob(res.data);
                //     let fileName = 'filename.xls';
                //     if ('download' in document.createElement('a')) {  // 非IE下载
                //         let link = document.createElement('a');
                //         link.download = fileName;
                //         link.style.display = 'none';
                //         link.href = URL.createObjectURL(blob);
                //         document.body.appendChild(link);
                //         link.click();
                //         URL.revokeObjectURL(link.href) ; // 释放URL 对象
                //         document.body.removeChild(link);
                //     } else {  // IE10+下载
                //         navigator.msSaveBlob(blob);
                //     }
                //
                //     console.log(blob);
                // })
            },
            commentlist(type, num) {
                if (type == 2) {
                    this.noteStatus = true
                    this.commentId = ''
                }
                if (num == 1) {
                    document.getElementById('comment').focus()
                } else if (num == 2) {
                    document.getElementById('notes').focus()
                }
                this.Type = type
                let self = this
                this.$axios.post(
                    1039,
                    {
                        Type: self.Type,
                        plan_id: self.Id,
                        uid: type == 2 ? self.userinfo.uid : ''
                    },
                    res => {
                        if (res.data.code == 1) {
                            if (self.Type == 1) {
                                self.data = res.data.message.data
                            } else {
                                self.note = res.data.message.data
                            }
                        } else {
                            //this.$message.error(res.data.message)
                        }
                    }
                )
            },
            comment(type) {
                this.Type = type
                let detail = ''
                if (this.Type == 1) {
                    detail = this.Content
                } else {
                    detail = this.NoteContent
                }
                if (detail == '') {
                    this.$message.error('请输入内容')
                    return false
                }
                let self = this
                this.$axios.post(
                    1040,
                    {
                        plan_id: self.Id,
                        Content: detail,
                        Type: self.Type,
                        userinfo: JSON.stringify(self.userinfo)
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.NoteContent = ''
                            self.Content = ''
                            if (self.Type == 1) {
                                this.data.push(res.data.message)
                            } else {
                                this.note.push(res.data.message)
                            }
                            //self.commentlist(self.Type);
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            foot() {
                document.getElementById('Meeting').scrollIntoView()
            },

            commentshow() {
                this.commentStatus = true
            },
            commentout() {
                this.commentStatus = false
            },
            noteshow() {
                this.showStatus = true
            },
            notetout() {
                this.showStatus = false
            },
            FileClose() {
                this.FileType = false
            },
            downCourse(url, name) {
                window.open(url + '?attname=' + name)
            },
            priewCourse(url, type) {
                this.FileStatus = this.CheckFileType(type);
                console.log(this.FileStatus)
                // console.log(this.FileStatus);
                // return false;
                if (this.FileStatus != 0) {
                    this.FileType = true
                    this.FileUrl = url
                } else {
                    this.$message.error('该课件不能预览')
                }
            },
            CheckFileType(type) {
                let status = 0
                if (type.indexOf('image/') == 0) {
                    status = 1
                } else if (type.indexOf('application/') == 0) {
                    if (type.indexOf('application/pdf') == 0) {
                        status = 3
                    } else {
                        status = 2
                    }
                }
                return status
            },
            ActivityImg(e, name, type) {
                let files = document.getElementById(name).files
                let self = this
                this.__uploadFile__(files).then(res => {
                    if (res.length > 0) {
                        this.$axios.post(
                            1028,
                            {
                                File: JSON.stringify(res),
                                CreateUserId: self.userinfo.uid,
                                CreateUserName: self.userinfo.name,
                                PersonId: self.Id,
                                Type: type
                            },
                            data => {
                                if (data.data.code == 1) {
                                    self.loadData()
                                }
                            }
                        )
                    } else {
                        this.$message.error('上传图片失败!')
                    }
                })
            },
            signup() {
                let self = this
                this.$axios.post(
                    1035,
                    {
                        Id: self.Id,
                        userinfo: JSON.stringify(self.userinfo)
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.loadData()
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            }
        }
    }
</script>
<style lang="less" scoped>
    .look_wrap {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        width: 100%;
        height: 90px;
        background: rgb(0, 0, 0);
    }

    .alert-video-shade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
        z-index: 1000;
    }

    .alert-video {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1100;
        video {
            width: 500px;
            height: 500px;
            outline: none;
        }
    }

    * {
        font-family: Microsoft YaHei;
    }

    /*.meeting{*/
    /*height:100%;*/
    /*overflow:auto;*/
    /*}*/
    .foot {
        text-align: center;
        position: fixed;
        right: 50px;
        top: 500px;
        .foot-button {
            margin-bottom: 20px;

            .foot-text {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
            }
        }
        .foot-button > button {
            width: 54px;
            height: 54px;
        }
    }

    .comment {
        position: relative;
        .comment-bth {
            position: absolute;
            bottom: 32px;
            right: 32px;
            color: rgba(153, 153, 153, 1);
            font-size: 18px;
            cursor: pointer;
        }
    }

    .list-status {
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        opacity: 0.8;
        height: 30px;
        line-height: 30px;
        background: #f0f0f0;
        border-radius: 3px;
        width: 75px;
        text-align: center;
    }

    .list-status-red {
        background: #ff3e82;
        border-radius: 3px;
        color: #ffffff;
    }

    .base-text {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    .drawer-content-bottom {
        margin-bottom: 20px;
    }

    .upload-bth {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .warp-padding {
        padding: 27px 23px;
        box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
        border-radius: 4px;
        margin-bottom: 20px;
        background: #ffffff;
        position: relative;
        /*background: red;*/
    }

    .coverImg {
        width: 520px;
        height: 292px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .tutor-text {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    .coverRight {
        flex: 1;
        margin-left: 28px;
        height: 290px;
        padding-top: 25px;
        margin-top: 20px;
        .title {
            width: 200px;
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
            margin-bottom: 20px;
        }
        .person-text {
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
        }
        .person-avatar {
            margin-left: 12px;
            margin-right: 12px;
        }
        .PersonInCharge {
            display: inline-block;
            margin-right: 15px;
        }
    }

    .base-warp {
        height: 260px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        overflow-y: auto;
        padding: 20px 24px 27px 20px;
        box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
        .group {
            margin-bottom: 15px;
            margin-right: 25px;
            .group-label {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
                width: 70px;
                display: block;
                white-space: nowrap;
            }
            .group-label::after {
                display: inline-block;
                margin-left: 4px;
                color: #f5222d;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: '*';
            }
            .flex-1 {
                flex: 1;
                text-align: left;
                margin-left: 8px;
                white-space: nowrap;
            }
            .label-tips {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
                width: 70px;
                display: block;
                white-space: nowrap;
            }
            .join-name {
                width: 42px;
                text-align: center;
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }

    .tutor {
        max-width: 288px;
        display: inline-block;
        margin-right: 60px;
        margin-bottom: 33px;
        .tutor-info {
            margin-left: 20px;
            flex: 1;
            .tutor-name {
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
                margin-bottom: 10px;
            }
            .tutor-info {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
            }
        }
    }

    .video {
        width: 162px;
        height: 91px;
        background: #fcfcfc;
        display: inline-block;
        margin-right: 10px;
        border: 1px solid #f3f3f3;
        text-align: center;
        cursor: pointer;
        position: relative;

        .video-title {
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: left;
        }

        .video-upload-time {
            /*width:102px;*/
            font-size: 12px;
            color: rgba(153, 153, 153, 1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
        video {
            width: 100%;
            height: 100%;
        }
        .shade {
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
            width: 100%;
            height: 100%;
            opacity: 0.7;
        }
        .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #fff;
            z-index: 100;
        }
    }

    .imsges {
        width: 157px;
        height: 88px;
        border: 1px solid rgba(243, 243, 243, 1);
        margin-bottom: 15px;
        margin-right: 15px;
        display: inline-block;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            display: inline-block;
        }
        .img-del {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(0, 0, 0, 0.4);
            height: 25px;
            line-height: 25px;
            width: 100%;
            text-align: center;
            color: #ffffff;
        }
    }

    .Paper-bg {
        background: url('http://sk-ims-cabinet.sikegroup.com/FkZMrbRt2npUZSw3aPSjpdp7-Ezz') no-repeat !important;
        background-size: 100% 100% !important;
        width: 36px;
        height: 36px;
        display: block;
    }

    .quire-content {
        background: rgba(231, 232, 237, 1);
        border-radius: 5px;
        padding: 5px 20px;
        margin-bottom: 10px;
        display: inline-flex;
        width: 100%;
        .quire-icon {
            display: block;
            background: url('http://sk-ims-cabinet.sikegroup.com/FkKQKL4LdI-i6SJmGVd7-XD4FazH') no-repeat;
            background-size: 100% 100%;
            width: 36px;
            height: 36px;
        }
        .quire-title {
            font-size: 14px;
            color: rgba(51, 51, 51, 1);
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 25px;
            overflow: hidden;
            height: 36px;
            line-height: 36px;
            display: block;
            flex: 1;
        }
    }

    .upload-file-item {
        width: 100px;
        padding: 15px 10px;
        margin-right: 10px;
        box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
        border-radius: 2px;
        display: inline-block;
        margin-bottom: 10px;
        i {
            font-size: 28px;
            margin-bottom: 10px;
        }
    }

    .popup-warp {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 90;
        .popup-shadw {
            position: fixed;
            background: rgba(0, 0, 0, 0.7);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 100;
        }
        .popup-content {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
        }
        .popup-close {
            position: fixed;
            z-index: 10000;
            right: 1%;
            top: 22%;
            color: red;
            cursor: pointer;
            width: 50px;
            font-size: 32px;
        }
    }

    .popup-close {
        position: fixed;
        z-index: 10000;
        right: 1%;
        top: 22%;
        color: red;
        cursor: pointer;
        width: 50px;
        font-size: 32px;
    }
</style>
